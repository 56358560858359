const qrInfo = {
  bgColor: '#FFFFFF', // 背景色 若为透明传空即可
  bodyType: 1, // 码点设置
  content: '',
  down: 0, // 0-显示图片 1-下载图片
  embedPosition: 0, // 添加文字位置 0-码外部 1-码内部
  embedText: '', // 添加文字内容,多行以逗号分割
  embedTextColor: '#000000', // 添加文字颜色
  embedTextSize: 38, // 添加文字大小
  eyeInColor: '#000000', // 码眼内颜色
  eyeOutColor: '#000000', // 码眼外颜色
  eyeType: 8, // 码眼设置
  eyeUseFore: 1, // 是否应用到码眼 0-不应用到码眼 1-应用到码眼
  fontFamily: 0, // 添加文字字体
  foreColor: '#000000', // 前景色
  foreColorImage: '', // 前景图填充
  foreColorTwo: '', // 渐变色2，渐变色1为foreColor
  foreType: 0, // 颜色类型 0-纯色 1-渐变色 2-前景色
  frameId: null, // 外框ID
  frameColor: '',
  gradientWay: 0, // 渐变方向 0-垂直 1-水平 2-对角 3-中心
  level: 'H', // 容错率：L-7% M-15% Q-25% H-30%
  logoShadow: 0, // 二logo投影 0-无投影 1-投影
  logoShap: 2, // ogo效果： 0-原图 1-矩形描边 2-圆形描边
  logoUrl: '', // logo地址
  margin: 2, // 二维码边距
  rotate: 30, // 二维码旋转角度
  size: 400 // 二维码大小
}

const qrParams = {
  logoId: -1,
  logoDisplayUrl: '',
  uploadLogoUrl: '',
  bgColor: '#FFFFFF',
  foreColor: '',
  foreColorFirst: '',
  foreColorTwo: '',
  embedTextList: ['', ''],
  frameColorFlag: 0,
  frameColor: '',
  foreType: 0,
  oldForeType: [{ foreColor: '#000000' }, { foreColor: '', foreColorTwo: '' }]
}

const getDefaultState = () => {
  return {
    content: '',
    qrUrl: '',
    qrInfo: { ...qrInfo },
    tpList: [],
    qrParams: { ...qrParams }
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_CONTENT: (state, content) => {
    state.content = content
  },
  SET_QR_URL: (state, url) => {
    state.qrUrl = url
  },
  SET_QR_INFO: (state, qrInfo) => {
    state.qrInfo = qrInfo
  },
  RESET_QR_INFO: (state) => {
    state.qrInfo = qrInfo
  },
  SET_QR_PARAMS: (state, qrParams) => {
    state.qrParams = qrParams
  },
  SET_TP_LIST: (state, tpList) => {
    state.tpList = tpList
  }
}

const actions = {
  resetQrInfo({ commit }) {
    commit('SET_QR_INFO', { ...qrInfo })
  },
  resetQrParams({ commit, params }) {
    const tempParams = params || qrParams
    commit('SET_QR_PARAMS', { ...tempParams })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

