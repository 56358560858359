<template>
  <div class="common-header">
    <a :href="indexUrl" class="logo">
      <img :src="logo" alt="互联二维码">
    </a>
    
    <ul class="nav" v-if="!loading">
      <li v-show="showMobile" class="nav-item">
        <a :href="indexUrl" target="_blank" >首页</a>
      </li>
      <!-- <li class="nav-item" v-if="$store.state.userInfo.vipLevel === 0">
        <a href="javascript:;" @click="toVip">VIP特权</a>
      </li> -->
      <!-- <li v-show="!showMobile" class="nav-item" > <a @click.prevent.stop="showDialogWin(true)" href="javascript:;">登录</a></li>
      <li v-show="!showMobile" class="nav-item login" style="margin-right: 10px;" @click.prevent.stop="showDialogWin(false)"><a >免费注册</a></li> -->
      <li v-show="showMobile" class="nav-item userInfo" :class="$store.state.userInfo.isPerson ? '' : 'comInfo' " >
        <span class="userinfo-dropdown">
          <i class="icon-vip" v-if="$store.state.userInfo.vipLevel > 0 && $store.state.userInfo.isPerson" ></i>
          <img class="icon-com" v-if="!$store.state.userInfo.isPerson" :src="$store.state.userInfo.companyLogo" alt="">
          {{ mobileNum }}<i class="el-icon-arrow-down el-icon--right" />
        </span>
        <div class="drop-box">
          <!-- <div class="btn-up" v-if="$store.state.userInfo.vipLevel === 0 && !$store.state.userInfo.companyUid"  @click="toVip">升级</div>
          <span class="time" v-if="$store.state.userInfo.vipLevel > 0 && !$store.state.userInfo.companyUid">有效期：{{expireTime}}</span>
          <div v-if="$store.state.userInfo.companyUid" class="btn-switch" @click="showSwitchList" >切换账号</div>
          <h3 :class="{vip: $store.state.userInfo.vipLevel > 0}">
            {{$store.state.vipMap[$store.state.userInfo.vipLevel]}}
            <i class="icon-vip" v-if="$store.state.userInfo.vipLevel > 0" ></i>
          </h3>
          <p v-if="$store.state.userInfo.vipLevel > 0 && $store.state.userInfo.companyUid" >有效期：{{expireTime}}</p>
          <el-progress :color="info.filePercent > 90 ? '#DF535A' : '#2578E3'" :stroke-width="3" :show-text="false" :percentage="info.filePercent"></el-progress>
          <p :style="{color: info.filePercent > 90 ? '#DF535A' : '#999'}">图片文件容量 {{getNum(info.useFileCapacity)}} / {{getInt(info.totalFileCapacity)}}</p>
          <el-progress :color="info.vodPercent > 90 ? '#DF535A' : '#2578E3'" :stroke-width="3" :show-text="false" :percentage="info.vodPercent"></el-progress>
          <p :style="{color: info.vodPercent > 90 ? '#DF535A' : '#999'}">音视频容量 {{getNum(info.useVodCapacity)}} / {{getInt(info.totalVodCapacity)}}</p>
          <el-progress :color="info.flowPercent > 90 ? '#DF535A' : '#2578E3'" :stroke-width="3" :show-text="false" :percentage="info.flowPercent"></el-progress>
          <p :style="{color: info.flowPercent > 90 ? '#DF535A' : '#999'}">音视频高清流量 {{getNum(info.useFlow)}} / {{getInt(info.totalFlow)}}</p>
          <el-divider></el-divider>
          <el-divider class="divider-two"></el-divider> -->
          <a href="javascript:;" @click="handleLogout('logout')">安全退出</a>
        </div>
      </li>
      <!-- <li class="nav-item" v-if="$store.state.userInfo.vipLevel === 0 && showMobile">
        <el-button size="mini" type="primary" plain @click="toVip" >升级</el-button>
      </li> -->
    </ul>
  </div>
</template>

<script>
import { logout } from '@/api/user'
import { mapState } from 'vuex'
import { calDateDiff } from "@/utils/time";
import {
 setToken,
  setTokenInSessionStorage,
  setUserInfo,
  setUserInfoInSessionStorage,removeToken, removeUserInfo, removeTokenSessionStorage, removeUserInfoSessionStorage
} from '@/utils/auth'
import {getUseInfo, getAccountList, changeAccount} from '@/api'
import moment from 'moment'
export default {
  name: 'Header',
  data(){
    return {
      showPop: false,
      active: '',
      indexUrl: process.env.VUE_APP_INDEX_URL,
      // list: ['免费版','基础版','高级版','旗舰版'],
      info: {},
      logo: process.env.VUE_APP_LOGO,
      loading: true,
      accountList: [],
      accountIndex: 0,
      accountMsg: ''
    }
  },
  computed: {
    expireTime(){
      return moment(this.$store.state.userInfo.vipExpireTime).format('YYYY-MM-DD')
    },
    ...mapState('user', {
      showMobile: state => state.showMobile,
      mobileNum: state => state.mobileNum,
      showWeChatLogin: state => state.showWeChatLogin,
      showLoginWin: state => state.showLoginWin,
      showRegWin: state => state.showRegWin
    }),
  },
  mounted(){
    this.getUserInfo()
    this.getData()
  },
  methods:{
    changeAccount(){
      if(this.accountMsg && !this.accountMsg.isLogin){
        let person = 1
        if(this.accountMsg.companyName){
          person = 0
        }
        changeAccount({
          isPersonLogin: person
        }).then(resData => {
          let userName = resData.userInfo.username;
          const token = resData.token;
          const tokenExpire = resData.tokenExpire;
          const diffDays = calDateDiff(new Date(), tokenExpire);
          // console.log('相差天数', diffDays)
          if (diffDays <= 0) {
            removeToken();
            removeTokenSessionStorage();
            setTokenInSessionStorage(token);
            setUserInfoInSessionStorage({ mobile: userName });
          } else {
            setToken(token, diffDays);
            setUserInfo({ mobile: userName }, diffDays);
          }
          window.location.reload()
        })
      }else{
        this.showPop = false
      }
    },
    chooseAccount(val, i){ // 选择账号
      this.accountMsg = val
      this.accountIndex = i
    },
    showSwitchList(){ // 展示选择账号弹窗
      getAccountList().then(resp => {
        this.accountList = resp
        this.accountList.map((val, i) => {
          if(val.isLogin){
            this.accountIndex = i
          }
        })
        this.showPop = true
      })
    },
    toVip(){
      window.open(process.env.VUE_APP_INDEX_URL+'vip')
    },
    toStatic(){
      this.$router.push('/scan')
    },
    handleLogout(command) {
      if (command === 'logout') {
        logout().then(() => {
          removeTokenSessionStorage()
          removeUserInfoSessionStorage()
          // removeTokenInLocalStorage()
          // removeUserInfoInlocalStorage()
          removeToken()
          removeUserInfo()
          this.$store.commit('RESET_STATE')
          window.location.href = process.env.VUE_APP_INDEX_URL
        }).catch(() => {
          this.$message({
            showClose: true,
            message: '账号退出失败',
            type: 'error',
            duration: 2000
          })
        })
      }
    },
    getUserInfo(){
      this.$store.dispatch('SET_USER_INFO')
    },
    getPrecent(use, total){
      if(use <= 0){
        return 0
      }
      let per = parseInt((use/total)*100)
      per = per > 100 ? 100 : per
      return per < 1 ? 1 : per
    },  
    getInt(num){
      let n = 0
      let unit = 'M'
      if(num > 1024){
        n = parseInt(num / 1024)
      }
      if(n > 1024){
        n = parseInt(n / 1024)
        unit = 'G'
      }
      return `${n}${unit}`
    },
    getNum(num){
      let n = 0
      let unit = 'K'
      if(num > 1024){
        n = Number((num / 1024).toFixed(1))
        unit = 'M'
      }else {
        n = num
      }
      if(n > 1024){
        n = Number((n / 1024).toFixed(2))
        unit = 'G'
      }
      n = n ? n : 0
      return `${n}${unit}`
    },
    getData(){
      getUseInfo().then(resp => {
        this.info = resp
        this.info.filePercent = this.getPrecent(resp.useFileCapacity,resp.totalFileCapacity)
        this.info.vodPercent = this.getPrecent(resp.useVodCapacity, resp.totalVodCapacity)
        this.info.flowPercent = this.getPrecent(resp.useFlow, resp.totalFlow)
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss">
.common-header{
    .logo{
      position: absolute;
      left: 20px;
      top: 21px;
      img{
        height: 32px;
        display: block;
      }
    }
    .nav {
      float: right;
      height: 100%;
      line-height: 75px;
      background: transparent;
      padding: 0;
      margin: 0;
    }

    .nav-item {
      position: relative;
      float: left;
      margin: 0 10px;
      color: #333;
      font-size: 15px;
      list-style: none;
      cursor: pointer;
      a{
        display: block;
        text-decoration: none;
        color: #333;
        transition: color .2s;
      }
      a:hover{
        color: $primary;
      }
      &.login {
        // width: 85px;
        margin-left: 5px;
        margin-right: 5px;
        a {
          display: inline;
          width: 85px;
          padding: 6.6px 16px;
          background: $primary;
          border-radius: 4px;
          font-size: 15px;
          color: #fff;
          &:hover{
            background-color:#3dbf6d;
          }
        }
      }
    }

    .el-dropdown-link {
      color: $primary;
      font-size: 15px;
      outline: none;
    }
    .el-icon-arrow-down {
      font-size: 15px;
    }

    .icon-vip{
      display: inline-block;
      width: 14px;
      height: 13px;
      background-image: url(./../assets/icon-vip.png);
      background-size: 100% 100%;
      vertical-align: -1px;
    }
    .icon-com{
      margin-right: 2px;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      vertical-align: -10px;
      object-fit: cover;
    }
    .userInfo{
      position: relative;
      padding-top: 31px;
      line-height: 0px;
      height: 80px;

      &.comInfo{
        padding-top: 23px;
      }
      .userinfo-dropdown{
        // margin-top: 31px
      }
      .userinfo-dropdown:hover .el-dropdown-link{
        opacity: 0.8;
      }
    
      .drop-box{
        display: none;
        position: absolute;
        top: 75px;
        right: 0;
        padding: 5px 20px 5px;
        text-align: center;
        width: 120px;
        line-height: 1;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(4, 18, 12, 0.35);
        border-radius: 3px;
        cursor: default;
        .el-button{
          position: absolute;
          right: 20px;
          top: 20px;
        }
        .btn-up{
          position: absolute;
          right: 20px;
          top: 20px;
          width: 56px;
          height: 28px;
          border: 1px solid #f9800f;
          border-radius: 4px;
          text-align: center;
          line-height: 26px;
          color: #f9800f;
          font-size: 13px;
          cursor: pointer;
          transition: all .3s;
          &:hover{
            color: #fff;
            background: #f9800f;
          }
        }
        .btn-switch{
          position: absolute;
          right: 20px;
          top: 20px;
          width: 78px;
          height: 28px;
          border: 1px solid $primary;
          border-radius: 4px;
          text-align: center;
          line-height: 26px;
          color: $primary;
          font-size: 13px;
          cursor: pointer;
          transition: all .3s;
          &:hover{
            background: $primary;
            color: #fff;
          }
        }
        .time{
          position: absolute;
          right: 20px;
          top: 26px;
          line-height: 20px;
          font-size: 13px;
          font-weight: 400;
          color: #999999;
        }
        h3{
          margin: 0;
          padding: 0;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
        .vip{
          color: #F87F0F;
        }
        .el-progress{
          margin-top: 24px;
          .el-progress-bar__outer{
            border-radius: 0;
          }
        }
        p{
          margin-top: 10px;
          font-size: 13px;
          // font-weight: 300;
          color: #999999;
        }
        .el-divider--horizontal{
          margin-top: 24px;
          margin-bottom: 5px;
        }
        .divider-two{
          &.el-divider--horizontal{
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }
        a{
          display: block;
          padding: 0 20px;
          margin-left: -20px;
          margin-right: -20px;
          font-size: 13px;
          // font-weight: 300;
          color: #333;
          line-height: 36px;
          &:hover{
            background: #f4f4f4;
            color: #333;
          }
        }

      }
      &:hover .drop-box{
        display: block;
      }
    }

  }
</style>