import Vue from 'vue'
import Vuex from 'vuex'
import {getUserInfo, getTemplateConfig} from '@/api'
import user from './modules/user'
import main from './modules/main'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    main
  },
  state: {
    userInfo: {
      vipLevel: 0
    },
    deviceMap: ['PC','iOS','Android','其他'],
    platMap: ['微信','QQ','支付宝','其他'],
    vipMap: ['免费版','基础版','高级版','旗舰版','','','','企业专属版'],
    ruleUserCount: [1,3,10,100,0,0,0,100],
    templateInfo: {},
    hasTemplate: false,
    templateIndex: 0
  },
  getters: {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    mobileNum: state => state.user.mobileNum
  },
  mutations: {
    SET_TEMPLATE_INDEX(state, info){
      state.templateIndex = info
    },
    SET_USER_INFO(state, info){
      state.user.mobileNum = info.username
      state.user.showMobile = true
      state.userInfo = info
    },
    SET_USER_TEMPLATE(state, info){
      state.templateInfo = info
    },
    SET_HAS_TEMPLATE(state, flag){
      state.hasTemplate = flag
    },
    RESET_STATE: () => {
      store.commit('user/RESET_STATE')
      store.commit('main/RESET_STATE')
    }
  },
  actions: {
    // SET_USER_INFO ({commit}){
    //   getUserInfo({},{showDialog: true}).then(resp => {
    //     commit('SET_USER_INFO', resp)
    //   })
    // }
    SET_USER_INFO ({commit}, callback){
      return getUserInfo().then(resp => {
        commit('SET_USER_INFO', resp)
        if(callback){
          callback()
        }
        if(resp.beautyTemplateId){
          return getTemplateConfig({id: resp.beautyTemplateId})
        }
      }).then(resp => {

        if(resp){
          commit('SET_USER_TEMPLATE', resp)
          commit('SET_HAS_TEMPLATE', true)
        }
      })
    }
  }
})

export default store
