<template>
  <div id="app">
    <router-view  />
  </div>
</template>

<script>
// import home from '@/views/Home'
export default {
  name: 'app',
  created(){
    document.domain = process.env.VUE_APP_DOMAIN
  },
  // components: {home}
}
</script>

<style>
button, input{
  font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Arial,sans-serif !important;
}
#app {
  font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
div,p,a,span,h3,h4,li,ul,ol,dl,dd,dt{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}


</style>
