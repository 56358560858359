import request from './request'

const BATH_PATH = process.env.VUE_APP_BASE_QRUSER_API + '/qruser'

export function login(data) {
  return request({
    url: '/vue-admin-template/user/login',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 获取验证码
 * @param data
 * @returns {AxiosPromise}
 */
export function getVerifyCode(data) {
  return request({
    url: BATH_PATH + '/auth/get_verify_code',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 提交注册信息
 * @param data
 */
export function register(data) {
  return request({
    url: BATH_PATH + '/auth/register',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 手机号登录
 * @param data
 * @returns {AxiosPromise}
 */
export function loginByUserName(data) {
  return request({
    url: BATH_PATH + '/auth/login_by_username',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 修改手机密码
 * @param data
 * @returns {AxiosPromise}
 */
export function modifyPwd(data) {
  return request({
    url: BATH_PATH + '/auth/change_by_password',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

// 传递token验证获取用户信息
export function getInfo(data) {
  return request({
    url: BATH_PATH + '/auth/get_userinfo',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

export function getInfo1(token) {
  return request({
    url: '/vue-admin-template/user/info',
    method: 'get',
    params: { token },
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

// 注销
export function logout() {
  return request({
    url: BATH_PATH + '/auth/loginout',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

// 获取微信小程序带参数的二维码
export function getWeChatMiniInfo() {
  return request({
    url: BATH_PATH + '/auth/get_applet_qrcode',
    method: 'get',
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

// 获取公众号绑定信息-V1.4
export function getWechatInfo() {
  return request({
    url: BATH_PATH + '/auth/get_wechatinfo',
    method: 'post',
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

// 设置公众号订阅信息-V1.4
export function setWechatSubscribe(data) {
  return request({
    url: BATH_PATH + '/auth/set_wechat_subscribe',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 获取微信活码列表
 * @param {Object} data 
 * @returns 
 */
export function listWxGroup(data){
  return request({
    url: BATH_PATH + '/console/group/list_wexin_group',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 获取群活码关联的二维码列表
 * @param {Object} data 
 * @returns 
 */
 export function getQrcodeList(data){
  return request({
    url: BATH_PATH + '/console/group/get_qrcode_list',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 修改微信活码名称
 * @param {Object} data 
 * @returns 
 */
 export function renameGroupmain(data){
  return request({
    url: BATH_PATH + '/console/group/rename_groupmain',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}

/**
 * 删除微信活码
 * @param {Object} data 
 * @returns 
 */
 export function delGroupmain(data){
  return request({
    url: BATH_PATH + '/console/group/del_groupmain',
    method: 'post',
    data,
    baseURL: process.env.VUE_APP_BASE_QRUSER_API
  })
}