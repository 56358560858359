import Cookies from 'js-cookie'

const tokenKey = 'zyduserinfo'
const userInfoKey = 'userInfo_data'

/** Cookie-Start **************************************************/
export function getToken() {
  return Cookies.get(tokenKey)
}

export const pubKey = `-----BEGIN PUBLIC KEY-----
                      MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANNUPQjGl9X+SvICG2GttjNHQyKJrzOg
                      Ykocu1I2ImQZOnlxhj6x1BuorYz/ESRllxcmoRYZKxOuIWGSzottwHkCAwEAAQ==
                      -----END PUBLIC KEY-----`

export function setToken(token, days) {
  return Cookies.set(tokenKey, token, { expires: days, domain: '.'+ process.env.VUE_APP_DOMAIN })
}

export function removeToken() {
  return Cookies.remove(tokenKey, {domain: '.'+ process.env.VUE_APP_DOMAIN})
}

export function getUserInfo() {
  return Cookies.get(userInfoKey)
}

export function setUserInfo(mobile, days) {
  return Cookies.set(userInfoKey, JSON.stringify(mobile), { expires: days, domain: '.'+ process.env.VUE_APP_DOMAIN })
}

export function removeUserInfo() {
  return Cookies.remove(userInfoKey, {domain: '.'+ process.env.VUE_APP_DOMAIN})
}
/** Cookie-End *************************************************/

// 从sessionStorage获取token
export function getTokenInSessionStorage() {
  return sessionStorage.getItem(tokenKey)
}

// 保存Token到SessionStorage
export function setTokenInSessionStorage(token) {
  sessionStorage.setItem(tokenKey, token)
}

// sessionStorage移除Token
export function removeTokenSessionStorage() {
  sessionStorage.removeItem(tokenKey)
}

/*
// 保存Token到localStorage
export function setTokenInlocalStorage(token) {
  localStorage.setItem(tokenKey, token)
}

// 从localStorage获取token
export function getTokenInlocalStorage() {
  return localStorage.getItem(tokenKey)
}

// localStorage移除token
export function removeTokenInLocalStorage() {
  localStorage.removeItem(tokenKey)
}
*/

// 从sessionStorage获取user_info
export function getUserInfoInSessionStorage() {
  return sessionStorage.getItem(userInfoKey)
}

// 保存user_info到SessionStorage
export function setUserInfoInSessionStorage(data) {
  sessionStorage.setItem(userInfoKey, JSON.stringify(data))
}

// sessionStorage移除user_info
export function removeUserInfoSessionStorage() {
  sessionStorage.removeItem(userInfoKey)
}

/*
// 从localStorage获取user_info
export function getUserInfoInlocalStorage() {
  return localStorage.getItem(userInfoKey)
}

// 保存user_info到localStorage
export function setUserInfoInlocalStorage(data) {
  localStorage.setItem(userInfoKey, JSON.stringify(data))
}

// localStorage移除user_info
export function removeUserInfoInlocalStorage() {
  localStorage.removeItem(userInfoKey)
}
*/

