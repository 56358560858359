import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location){
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.replace = function replace(location){
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/apply',
    children: [
      {
        path: 'data',
        name: 'data',
        component: () => import(/* webpackChunkName: "data" */ '../views/data.vue')
      },
      {
        path: 'promotion',
        name: 'promotion',
        component: () => import(/* webpackChunkName: "data" */ '../views/promotion.vue')
      },
      {
        path: 'payAmount',
        name: 'payAmount',
        component: () => import(/* webpackChunkName: "data" */ '../views/payAmount.vue')
      },
      {
        path: 'payOrder',
        name: 'payOrder',
        component: () => import(/* webpackChunkName: "data" */ '../views/payOrder.vue')
      },
      {
        path: 'commission',
        name: 'commission',
        component: () => import(/* webpackChunkName: "data" */ '../views/commission.vue')
      },
      {
        path: 'info',
        name: 'info',
        component: () => import(/* webpackChunkName: "data" */ '../views/info.vue')
      },
      {
        path: 'jump',
        name: 'jump',
        component: () => import(/* webpackChunkName: "data" */ '../views/jump.vue')
      },
    ]
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "data" */ '../views/apply.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
