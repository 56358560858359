<template>
  <el-container class="page-home">
    <el-header height="75px" >
      <common-header/>
    </el-header>
    <el-container>
      <el-aside width="220px">
        <el-menu
          :default-active="active"
          class="el-menu-vertical-demo"
          @select="selectMenu"
          @open="handleOpen"
          @close="handleClose">
          <el-menu-item index="/data" >
            <svg-icon icon-class="menu-index"></svg-icon>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-menu-item index="/promotion" >
            <svg-icon icon-class="menu-tgtj"></svg-icon>
            <span slot="title">推广统计</span>
          </el-menu-item>
          <el-menu-item index="/payAmount" >
            <svg-icon icon-class="menu-zftj"></svg-icon>
            <span slot="title">支付统计</span>
          </el-menu-item>
          <el-menu-item index="/payOrder" >
            <svg-icon icon-class="menu-zfdd"></svg-icon>
            <span slot="title">支付订单</span>
          </el-menu-item>
          <el-menu-item index="/commission" >
            <svg-icon icon-class="menu-yjjs"></svg-icon>
            <span slot="title">佣金结算</span>
          </el-menu-item>
          <el-menu-item index="/info" >
            <svg-icon icon-class="menu-zhxx"></svg-icon>
            <span slot="title">账号信息</span>
          </el-menu-item>          
        </el-menu>
      </el-aside>
      <el-main ref="main">
        <router-view @scrollTop="scrollTop" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import moment from 'moment'
import commonHeader from '@/components/header' 
import {getPartner} from '@/api'
export default {
  name: 'Home',
  data(){
    return {
      showPop: false,
      active: '',
      indexUrl: process.env.VUE_APP_INDEX_URL,
      // list: ['免费版','基础版','高级版','旗舰版'],
      info: {},
      logo: process.env.VUE_APP_LOGO,
      loading: true,
      accountList: [],
      accountIndex: 0,
      accountMsg: '',
    }
  },
  computed: {
    expireTime(){
      return moment(this.$store.state.userInfo.vipExpireTime).format('YYYY-MM-DD')
    },
    ...mapState('user', {
      showMobile: state => state.showMobile,
      mobileNum: state => state.mobileNum,
      showWeChatLogin: state => state.showWeChatLogin,
      showLoginWin: state => state.showLoginWin,
      showRegWin: state => state.showRegWin
    }),
  },
  components: {
    commonHeader
  },
  watch: {
    $route(val){
      this.active = val.path
    }
  },
  created(){
    this.getInfo()
  },
  mounted(){
    this.active = this.$route.path
    // this.getUserInfo()
  },
  methods:{
    getInfo(){
      getPartner().then(resp => {
        if(resp === null || resp.status !== 1){
          this.$router.push('/apply')
        }
      })
    },
    scrollTop(){
      this.$refs.main.$el.scrollTop = 0
    },
    
    selectMenu(index){ // 强制刷新
  
      if(this.$route.path === index){
        this.$router.replace({
          path: "/jump"
        })
        this.$nextTick(() => {
          this.$router.replace({
            path: index
          })
        })
      }else{
        this.$router.push({
          path: index
        })
      }
    },
    handleOpen(){
      
    },
    handleClose(){

    }
  }
}
</script>

<style lang="scss">
.select-list-pop{
  padding: 24px 30px;
  li{
    margin-top: 18px;
    padding: 17px;
    min-height: 90px;
    display: flex;
    align-items: center;
    background: #FCFCFC;
    border: 1px solid #E7E7E7;
    border-radius: 5px;
    cursor: pointer;
    &:first-child{
      margin-top: 0;
    }
    .img{
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }
    h3{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      p{
        display: inline-block;
        max-width: 280px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        @include textOverflow;
      }
      
      span{
        margin-left: 8px;
        display: inline-block;
        width: 63px;
        height: 20px;
        text-align: center;
        background: $primary;
        border-radius: 3px;
        color: #fff;
        font-size: 13px;
        line-height: 20px;
        font-weight: normal;
        vertical-align: 3px;
      }
    }
    .info{
      flex-grow: 1;
      margin-left: 12px;
      .p{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
      }
    }
    .logined{
      color: $primary;
    }

  }
  .on{
    border-color: $primary;
    box-shadow: 1px 1px 0 $primary, -1px -1px 0 $primary, 1px -1px 0 $primary, -1px 1px 0 $primary;
  }
}
.page-home{
  height: 100vh;
  font-size: 14px;
  color: #333;
  .el-header{
    position: relative;
    height: 75px;
    box-shadow: 0px 0px 4px 0px rgba(0, 19, 11, 0.18);
    z-index: 9;
  }
  .el-aside{
    padding-top: 24px;
    height: calc(100vh - 75px);
    &::-webkit-scrollbar{
      display: none;
    }
    .el-submenu__icon-arrow{
      font-weight: bold;
    }
    .svg-icon{
      margin-right: 10px;
      font-size: 17px;
      vertical-align: -5px;
    }
    .el-menu-item:hover, .el-menu-item:focus, .el-submenu__title:hover{
      background: none;
      color: $primary;
    }
    .el-submenu .el-menu-item{
      padding-left: 46px !important;
    }
    .el-menu-item{
      margin: 0 auto 10px auto;
      width: 164px;
      height: 34px;
      line-height: 32px;
      border-radius: 4px;
      transition: none;
      &.is-active{
        background: $primary;
        font-weight: bold;
        color: #fff;
      }
    }
    .el-submenu{
      margin: 0 auto 10px auto;
      width: 164px;
      // height: 34px;
      line-height: 32px;
      border-radius: 4px;
   
      .el-submenu__title{
        height: 34px;
        line-height: 34px;
        border-radius: 4px;
        transition: none;
      }
      .el-menu-item{
        // padding: 0 !important;
        min-width: auto;
        height: 24px;
        line-height: 24px;
        &.is-active{
          background: none;
          color: $primary;
          font-weight: bold;
        }
      }
      &.is-active{
        .el-submenu__title{
          color: #fff;
          background: $primary;
          i{
            color: #fff;
          }
          &:hover{
            background: $primary;
          }
        }

      }
    }
  }
  .el-main{
    position: relative;
    height: calc(100vh - 75px);
    padding: 24px;
    background: #eff1f4;
    overflow: auto;
    @include scrollBar;
  }
  .el-menu{
    border-right: none;
  }
  .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next, .el-pagination.is-background .el-pager li{
    background: #f3f3f3;
  }
  .el-pagination.is-background .btn-prev:disabled, .el-pagination.is-background .btn-next:disabled{
    background: #f3f3f3;
    color: #c3c3c3;
  }
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background-color: #f4f4f4 !important;
}
.el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus{
  background-color: #f4f4f4 !important;
}
.el-message-box__headerbtn .el-message-box__close{
  font-weight: bold;
}

.el-dialog__close{
  font-weight: bold !important;
}

.table-box{
  table{
    tr{
      &:nth-child(even){
        td{
          background: #fafafa;
        }
      }
      &:hover{
        td{
          background: #f5f7fa;
        }
      }
    }
  }
}

</style>
