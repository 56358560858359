import { logout, getInfo } from '@/api/user'
import { removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    // token: getToken(),
    name: '',
    avatar: '',
    showDialogWin: false, // 显示登录注册对话框
    showWeChatLogin: false, // 显示微信登录界面
    showLoginWin: false, // 显示手机登录界面
    showRegWin: false, // 显示注册界面
    showMobile: false,
    mobileNum: ''
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  // 点击登录按钮跳转到登录\注册界面
  SET_SHOW_DIALOGWIN: (state, { showDialogWin, showWeChatLogin, showLoginWin, showRegWin }) => {
    state.showDialogWin = showDialogWin
    state.showWeChatLogin = showWeChatLogin
    state.showLoginWin = showLoginWin
    state.showRegWin = showRegWin
  },
  SET_SHOW_MOBILE: (state, showMobile) => {
    state.showMobile = showMobile
  },
  SET_MOBILE_NUM: (state, mobileNum) => {
    state.mobileNum = mobileNum
  }
}

const actions = {
  // user login
  // login({ commit }, userInfo) {
  //   const { username, password } = userInfo
  //   return new Promise((resolve, reject) => {
  //     login({ username: username.trim(), password: password }).then(response => {
  //       const { data } = response
  //       commit('SET_TOKEN', data.token)
  //       setToken(data.token)
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // get user info
  getInfo({ state }) {
    return new Promise((resolve, reject) => {
      // getInfo(state.token).then(response => {
      //   const { data } = response
      //   if (!data) {
      //     return reject('Verification failed, please Login again.')
      //   }
      //   const { name, avatar } = data
      //   commit('SET_MOBILE_NUM', name)
      //   commit('SET_AVATAR', avatar)
      //   resolve(data)
      // }).catch(error => {
      //   reject(error)
      // })
      getInfo(state.token).then(response => {
        console.log('getInfo：', response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        // resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

