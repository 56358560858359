import moment from 'moment'

/**
 * 计算两个时间戳相差的天数
 * @param nowDate
 * @param newDate
 * @returns {number}
 */
export function calDateDiff(nowDate, newDate) {
  if (!nowDate || !newDate) {
    return -1
  }
  return Math.ceil(moment.duration(moment(newDate).diff(moment(nowDate))).asDays())
}
